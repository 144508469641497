// ToastModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Toast.css'; // Import your CSS file for styling

export default function ToastModal({ type, heading, message, isVisible, onClose }) {
  return (
    <Modal show={isVisible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={type}>
        {message}
      </Modal.Body>
      
    </Modal>
  );
}
