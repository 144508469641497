import React, { useEffect, useRef, useState } from "react";
import "./ICPResult.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "rc-slider/assets/index.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import ICPResultTable from "./ICPResultTable";
import ICPTitle from "./ICPTitle";
import Information from "./Information";
import Columns from "./Columns";
import {
  ITEMS_PER_PAGE,
  intentSignal,
  signalRank,
} from "../../../utils/constants";
import ExportFile from "../Modals/ExportFile/ExportFile";
import {
  getIcpScore,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../../context/actions/User";
import { toast } from "react-toastify";
import InformationStaff from "../ICPResult/InformationStaff";
import SearchModel from "../Modals/ExportFile/SearchModel";
import ICPFilters from "./IcpFilters";

const ICPResult = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [downloadRemainingCounts, setDownloadRemainingCounts] = useState(0);
  const icp_payload = JSON.parse(localStorage.getItem("icp_payload"));
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  //ICP DATA after calculation
  const abc = useSelector((state) => state?.icpScoreResult?.icpScore?.data);
  const icp_all_data = useSelector((state) => state?.icpScoreResult?.icpScore);
  const [totalRecordsCount, setTotalRecordsCount] = useState(
    icp_all_data?.totalCount
  );
  const [icpData, setIcpData] = useState(
    useSelector((state) => state?.icpScoreResult?.icpScore?.data)
  );
  const pageVisibility = useSelector(
    (state) =>
      state?.userSubscriptionData?.userSubscrptionData?.data
        
  );
  //for pagination
  const itemPerPage = ITEMS_PER_PAGE;
  // const itemPerPage = 3;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  //export selected row
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkSelectAll, setCheckSelectAll] = useState([]);
  //open Modal
  const [openModal, setOpenModal] = useState(false);
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();

  //this dataToRender is for updating the data when the page is changing thats why taken in useState
  const [dataToRender, setDataToRender] = useState(
    icpData?.slice(0, itemPerPage)
  );

  /* Column Visibility */
  const [columnVisibility, setColumnVisibility] = useState({
    companyName: true,
    icpScore: true,
    website: true,
    domain: true,
    Topics: true,
    mainIndustry: true,
    sab_industry_name:true,
    companySize: true,
    revenue: true,
    country: true,
   
  });
  //user subscription data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  const toggleColumnVisibility = (columnName) => {
    console.log(columnName);
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };
  const tableContainerRef = useRef(null);

  // sorting data
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });
  const all_industry = useSelector(
    (state) => state?.icpScoreResult?.icpScore?.industry_name
  );

  //filters for showing data
  const [filters, setFilters] = useState({
    industry: [],
    icpScore: [55, 95],
    companySize: [],
    companyRevenue: [],
    country: [],
    location: []
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  //below useEffect for getting user subscription details
  // const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
            setDownloadRemainingCounts(
              result.data.per_day_downlod_limit_remening
            );
          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  // remove setState make it const if necessary
  const [totalPages, setTotalPages] = useState(
    Math.ceil(Math.max(icp_all_data?.totalCount, 0) / itemPerPage)
  );

  // Generate an array of page numbers based on the totalPages
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const getIcpData = (pageNumber, searchquery, filters) => {
    let { location: filtersLocation, ...otherFilters } = filters; // Extract location from filters
    let payload = {
      product_category_name: icp_payload?.product_category_name,
      product_sub_category_name: icp_payload?.product_sub_category_name,
      topics: icp_payload?.topics,
      location: filtersLocation?.length > 0 ? filtersLocation : icp_payload?.location,
      user_id: userId,
      page: pageNumber,
      search_query: searchquery,
      ...otherFilters,
    };
    if (isStaff) {
      payload = { ...payload, staff_id: userData.userInfo?.user_staff?.user };
    }
    
    dispatch(
      getIcpScore(payload, token, (result) => {
        if (result?.status === 200) {
          setDataToRender(result?.data);
          setCurrentPage(pageNumber);
          const pages = Math.ceil(
            Math.max(result?.totalCount, 0) / itemPerPage
          );
          setTotalRecordsCount(result?.totalCount);
          setTotalPages(pages);
          setSelectAll(checkSelectAll.includes(pageNumber));
        }
      })
    );
  };
  const handlePageChange = (pageNumber) => {
    
    if(pageNumber>pageVisibility?.user_page_visibility){
      setCurrentPage(pageNumber);
      return true
    }
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      let processedFilters = getLastIndexData(filters);
      getIcpData(pageNumber, searchInput, processedFilters);
    }
  };
  useEffect(() => {
    let timerOut = setTimeout(async () => {
      if (searchInput != "") {
        getIcpData(1, searchInput,{});
      } else {
        setDataToRender(icpData);
        setTotalRecordsCount(icp_all_data?.totalCount);
        setTotalPages(
          Math.ceil(Math.max(icp_all_data?.totalCount, 0) / itemPerPage)
        );
      }
    }, 2000);
    return () => clearTimeout(timerOut);
  }, [searchInput]);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    const trimmedInput = input.trim();
    setSearchInput(trimmedInput === "" ? "" : input);
  };

  const handleExportModel = () => {
    if (downloadRemainingCounts < selectedRows.length) {
      setIsDialogOpen(true);
      return;
    }
    setOpenModal(true);
  };
  const handleClose = () => setIsDialogOpen(false);

  const filterData = (input, pageNumber) => {
    const lowercasedInput = input.toLowerCase();
    const filtered = icpData.filter((data) =>
      data.company_name.toLowerCase().includes(lowercasedInput)
    );

    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    let paginatedData = filtered.slice(startIndex, endIndex);

    // Set the filtered and paginated data
    setCurrentPage(pageNumber);
    setDataToRender(paginatedData);
  };

  useEffect(() => { }, [checkSelectAll]);

  const handleSelectAll = () => {
    // Toggle the selectAll state
    setSelectAll((prevSelectAll) => !prevSelectAll);

    // Update the selectedRows based on the selectAll state

    const allRowIds = sortedData.map((data) => {
      let paylaod = {
        id: data.id,
        icp_score: data.icp_score,
        topics: data?.Topics,
      }
      if (typeof data.id === 'string') {
        paylaod['replica_id'] = data.replica_id
        
      }
      
      return paylaod
    });

    if (!selectAll) {
      // Add rows from the current page to selectedRows
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        ...allRowIds.filter(
          (row) => !prevSelectedRows.some((prevRow) => prevRow.id === row.id)
        ),
      ]);

      // Add the current page to checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) => [
        ...prevCheckSelectAll,
        currentPage,
      ]);
    } else {
      // Remove rows from the current page from selectedRows
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (row) => !allRowIds.some((newRow) => newRow.id === row.id)
        )
      );

      // Remove the current page from checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) =>
        prevCheckSelectAll.filter((page) => page !== currentPage)
      );
    }
  };

  const handleCheckboxChange = (id, icpScore, topics, replica_id) => {
    
    if (selectedRows.some((row) => row.id === id)) {
      // If the row is already selected, remove it from selectedRows
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.id !== id)
      );
      if (selectedRows.length + 1 === sortedData.length) {
        setSelectAll(true); // Check the header checkbox if all individual checkboxes are checked
      } else {
        setSelectAll(false); // Uncheck the header checkbox if any individual checkbox is unchecked
      }
    } else {
      // If the row is not selected, add it to selectedRows along with its icp_score
      let paylaod = { id, icp_score: icpScore, topics: topics }
      if (typeof id === 'string') {
        paylaod['replica_id'] = replica_id

      }
      
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        paylaod,
      ]);

      if (selectedRows.length + 1 === sortedData.length) {
        setSelectAll(true); // Check the header checkbox if all individual checkboxes are checked
      }
    }
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const handleFilterChange = (filterType, value) => {
    console.log(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: Array.isArray(prevFilters[filterType])
        ? prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter((item) => item !== value)
          : [...prevFilters[filterType], value]
        : value,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      industry: [],
      icpScore: [55, 95],
      companySize: [],
      companyRevenue: [],
      country: [],
      location: []
    });

    setSearchInput("");
    setSelectedRows([]);
    setSelectAll(false);
    getIcpData(1, "", {});
  };
  const getLastIndexData = (filters) => {
    const processedFilters = {};

    for (const key in filters) {
      if (filters[key].length > 0) {
        if (key === "industry") {
          // Take the last array inside the industry property
          const lastIndustryNames = filters[key][filters[key].length - 1];
          const industryIds = lastIndustryNames
            .map((name) => {
              const matchedIndustry = all_industry.find(
                (ind) => ind.industry_name === name
              );
              return matchedIndustry ? matchedIndustry.industry_ids : null;
            })
            .filter((id) => id !== null); // Filter out any null values

          // Flatten the array if necessary
          processedFilters[key] = Array.isArray(industryIds[0])
            ? industryIds.flat()
            : industryIds;
        } else if (filters[key].some((item) => Array.isArray(item))) {
          // Take the last array inside the property
          processedFilters[key] = filters[key][filters[key].length - 1];
        } else {
          // If the property does not contain arrays, keep it as is
          processedFilters[key] = filters[key];
        }
      } else {
        processedFilters[key] = [];
      }
    }

    return processedFilters;
  };

  const handleApplyFilters = () => {
    let processedFilters = getLastIndexData(filters);
    getIcpData(1, searchInput, processedFilters);
  };

  const handleRangeChange = (type, values) => {
    setFilters({
      ...filters,
      [type]: values,
    });
  };

  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };

  //sorting function
  const sortedData = [...dataToRender].sort((a, b) => {
    let columnA, columnB;

    if (sortBy.column === "topics") {
      const aSignal = intentSignal(a.Topics?.length)?.props?.children || "-";
      const bSignal = intentSignal(b.Topics?.length)?.props?.children || "-";
      columnA = signalRank[aSignal];
      columnB = signalRank[bSignal];
    } else {
      columnA = a[sortBy.column];
      columnB = b[sortBy.column];
    }

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });

  return (
    <>
      {openModal ? (
        <ExportFile
          showModal={openModal}
          onClose={onClose}
          icpData={icpData}
          selectedRows={selectedRows}
          type="ICP"
          downloadRemainingCounts={downloadRemainingCounts}
        />
      ) : null}
      <div className="pageHeadingBlock commonHeading">
        <ICPTitle title="VAIS Results" backTo="/build-your-vais" />
        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}{" "}
      </div>
      <p style={{ textAlign: "center", fontSize: "15px" }}>
        Selected subcategory : {icp_payload?.product_sub_category_name}
      </p>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper abmResults" ref={tableContainerRef}>
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <ICPFilters
                      filters={filters}
                      setFilters={setFilters}
                      handleFilterChange={handleFilterChange}
                      handleResetFilters={handleResetFilters}
                      handleApplyFilters={handleApplyFilters}
                      handleRangeChange={handleRangeChange}
                      icpData={icpData}
                      type="ICP"
                    />
                    <Columns
                      columnVisibility={columnVisibility}
                      toggleColumnVisibility={toggleColumnVisibility}
                      setColumnVisibility={setColumnVisibility}
                      type="ICP"
                    />
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <span className="pagination-page-block">
                      <strong>Total Records : {totalRecordsCount}</strong>
                    </span>{" "}
                    <div className="d-flex align-items-center">
                      <span
                        style={{ marginLeft: "5rem" }}
                        className="page-span"
                      >
                        {" "}
                        Page :
                      </span>
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div
                        className="prev paginationItem"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div
                        className="next paginationItem"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ScrollButton /> */}
              <FullScreen handle={handle}>
                <ICPResultTable
                  icpData={dataToRender}
                  columnVisibility={columnVisibility}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  handleSelectAll={handleSelectAll}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSort={handleSort}
                  sortedData={sortedData}
                  sortBy={sortBy}
                  type="ICP"
                  currentPage={currentPage}
                  getUserSubscriptionData={getUserSubscriptionData}
                />
              </FullScreen>
            </div>
            <div className="tableSelectionRecord">
              <div className="selectedItems">
                <h6>{`${selectedRows.length} Item${selectedRows.length !== 1 ? "s" : ""
                  } Selected`}</h6>

                <p>
                  <strong>Note:</strong> One credit will be deducted for each
                  entry exported from the VAIS results.
                </p>
              </div>

              <div className="exportItems">
                <Link
                  to=""
                  className={`btn ${selectedRows.length === 0 ? "disabled" : ""
                    }`}
                  onClick={handleExportModel}
                >
                  Export
                </Link>
              </div>
            </div>
            {isDialogOpen && (
              <SearchModel
                isDialogOpen={isDialogOpen}
                message="Your Per Day Download Limit Reached"
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ICPResult;
