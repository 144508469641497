import React from 'react';
import './FreeTrialPage.css';
import { useNavigate } from 'react-router-dom';
import vailsresultblur from '../../../../assets/images/vailsresultblur.jpg';
import abmlal from '../../../../assets/images/abmlal.jpg';
export default function FreeTrialPage({type}) {
    const navigate = useNavigate();
    
    return (
        <div className="row freepageContentBlock">
            <div className="col-12 col-lg-12">
                <div className="freecontentWrapper FindProspects">
                    <div 
                        className="bgTable"  
                        style={{ backgroundImage: `url(${type==='VAIS'?vailsresultblur:abmlal})` }} // Correctly format the URL
                    >
                        <h1 className="textOverlay">
                            To access this page, you will need to upgrade your plan. Please consider doing so to enjoy the additional features.
                        </h1>
                        <button className="ctaButton" onClick={() => navigate("/subscription-plans")}>
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
