import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Select from "react-select";
import "./ABMLAL.scss";
import { useNavigate } from "react-router-dom";
import excelIcon from "../../assets/images/csvIcon.png";
import excelTemplate from "../../assets/alltemplates/abmtemplate.csv";
import { saveAs } from "file-saver";
import { Formik, Form } from "formik";

import {
  checkDomainNames,
  getAbmScore,
  getLAlListResult,
  getProductSubCategory,
  getRemainingFilterdData,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import {
  ABM_Validation,
  LAL_Validation,
} from "../../utils/Validations/Validations";
import { useDispatch, useSelector } from "react-redux";
import Information from "../BuildICP/ICPResult/Information";
import DomainModel from "./DomainModel";
import InformationStaff from "../BuildICP/ICPResult/InformationStaff";
import SearchModel from "../BuildICP/Modals/ExportFile/SearchModel";
import ToastModal from "../../components/Modal/TosterModel";
import { RetryIcon } from "stream-chat-react";
import loaderMessages from "../../json/loaderMessages.json"
const ABMLAL = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [unregisteredDomains, setUnregisteredDomains] = useState([]);
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  const [domainlength, setDomainLength] = useState(0);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [getAllSubProductCategories, setGetAllSubProductCategories] = useState(
    []
  );
  const isSubscribed = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData
  );
  // const udesubscriptiondata = useSelector(
  //   (state) => state?.userSubscriptionData?.userSubscrptionData?.data
  // );
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  // const [getSellingPrice, setGetSellingPrice] = useState([]);
  // const [getGeoLocation, setGetGeoLocation] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(100);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [toastData, setToastData] = useState({});
  const [serchlimitError, setSearchlimitError] = useState(false);
  const [clickFrom, setClickFrom] = useState("")
  const [limitError, setLimitError] = useState(false)

  const [loading, setLoading] = useState(false);
  const [randomMessage, setRandomMessage] = useState("");


  const initialValues = {
    product_sub_category_name: "",
    price_range: "",
    geolocation: "",
    uploadAbmFile: null,
  };

  const initialLALValues = {
    product_sub_category_name: "",
    uploadLALFile: null,
  };
  // // this useEffect for getting the user subscription data
  // useEffect(() => {
  //   if (userId) {
  //     const payload = {
  //       user_id: userId,
  //     };
  //     dispatch(
  //       getUserSubscriptionPlanDetails(payload, token, (result) => {
  //         if (result.status === 200) {
  //           setGetUserSubscriptionData(result?.data);
  //         } else if (result.status === 404) {
  //           navigate("/subscription-plans");
  //           toast("Please Buy Subscription Package!", { autoClose: 1200 });
  //         }
  //       })
  //     );
  //   }
  // }, []);
  useEffect(() => {
    let interval;

    if (loading) {
      // Set the initial message
      setRandomMessage(getRandomMessage());

      // Change the message every 5 seconds
      interval = setInterval(() => {
        setRandomMessage(getRandomMessage());
      }, 4000);
    } else {
      // Clear interval when loading is false
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [loading]);

  // For Randomized the loading notes

  // For Randomized the loading notes END


  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * loaderMessages.length);
    return loaderMessages[randomIndex];
  };

  useEffect(() => {
    if (isSubscribed?.status === 404) {
      navigate("/subscription-plans");
      toast("Please Buy Subscription Package!", { autoClose: 1200 });
    }
  }, [isSubscribed]);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result?.status === 200) {
            setGetUserSubscriptionData(result?.data);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  //get product sub category
  useEffect(() => {
    dispatch(
      getProductSubCategory(token, (result) => {
        const convertedData = result.product_sub_category_list.map((item) => ({
          value: item.product_sub_category_name,
          label: item.product_sub_category_name,
        }));
        // Sort the array alphabetically by product_sub_category_name
        //The localeCompare method is used for string comparison with sensitivity set to
        // 'base' to ensure case-insensitive sorting.
        convertedData.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
        );
        setGetAllSubProductCategories(convertedData);
      })
    );
  }, []);

  //selling price and geolocation dropdown
  // useEffect(() => {
  //   const payload = { limit: limit, page_number: currentPage };
  //   dispatch(
  //     getRemainingFilterdData(payload, token, (result) => {
  //       const convertedSellingPriceListData =
  //         result.product__selling_price_list.map((item) => ({
  //           value: item.value,
  //           label: item.price_range,
  //         }));

  //       const convertedGeoLocation = result.geolocations.map((item) => ({
  //         value: item.geolocation,
  //         label: item.geolocation,
  //       }));

  //       setGetSellingPrice(convertedSellingPriceListData);
  //       setGetGeoLocation(convertedGeoLocation);
  //     })
  //   );
  // }, []);

  const customStyles = {
    option: (base, { isSelected }) => {
      return {
        ...base,
        color: isSelected ? "#414141" : "#414141",
        backgroundColor: isSelected ? "#fafafa" : "#ffffff",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      };
    },

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0",
      border: "none",
      borderBottom: "1px solid #E6E6E6",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
      "&:focus": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "6px 0",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#414141",
      },
    }),
    Input: (base) => ({
      ...base,
      padding: "0",
      margin: "0",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#414141",
      margin: "0",
    }),
  };
  const show_daily_limit_exide = () => {
    let limit = isSubscribed?.data?.per_day_search_limit || 0
    let data = {
      type: 'error',
      heading: 'Per day search limit reached!',
      message: `You've used your ${limit} search credits for today. Please wait until tomorrow for them to refresh.`
    }
    setToastData(data);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
      setSearchlimitError(true)
    }, 4000);
  }

  // handlesubmit for verify ABM
  const handleSubmit = (values) => {
    if (isSubscribed.data.is_free_trial && isSubscribed.data.per_day_search_limit_left === 0) {
      show_daily_limit_exide()
      return
    }
    // Check if uploadAbmFile is present
    if (values.uploadAbmFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Parse the uploaded file content based on file type
        const fileType = values.uploadAbmFile.name
          .split(".")
          .pop()
          .toLowerCase();
        // console.log("e.target.result==", e.target.result);
        if (fileType === "csv") {
          parseCSV(e.target.result, values);
        } else if (fileType === "xlsx") {
          parseXLSX(e.target.result, values);
        } else {
          toast.error("Unsupported file type");
        }
      };
      reader.readAsBinaryString(values.uploadAbmFile);
    }
  };

  //parse function for ABM content for both csv and XLSX
  const parseCSV = (csvContent, formValues) => {
    Papa.parse(csvContent, {
      complete: (result) => {
        const domainNames = result.data.map((row) => row.Domain);
        if (domainNames === undefined)
          toast("provided file is empty, please use ABM template !");
        else {
          if (domainNames.length === 0) {
            toast("Please Include Domain Name In File !", { autoClose: 1000 });
          } else {
            dispatchReduxAction(formValues, domainNames);
          }
        }

        // dispatchReduxAction(formValues, domainNames);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const parseXLSX = (xlsxContent, formValues) => {
    const workbook = XLSX.read(xlsxContent, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const domainIndex = parsedData[0]?.indexOf("Domain");
    if (domainIndex === undefined)
      toast("provided file is empty, please use ABM template !");
    else {
      const domainNames = parsedData
        .slice(1)
        .map((row) => row[domainIndex])
        .filter((domain) => domain); // Use index obtained from indexOf
      if (domainNames.length === 0) {
        toast("Please Include Domain Name In File !", { autoClose: 1000 });
      } else {
        dispatchReduxAction(formValues, domainNames);
      }
    }
  };

  const getDomainNameFromSelectedFile = (xlsxContent) => {
    const workbook = XLSX.read(xlsxContent, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const domainIndex = parsedData[0]?.indexOf("Domain");
    if (domainIndex === undefined)
      toast("provided file is empty, please use ABM template !");
    else {
      const domainNames = parsedData
        .slice(1)
        .map((row) => row[domainIndex])
        .filter((domain) => domain); // Use index obtained from indexOf
      if (domainNames.length === 0) {
        toast("Please Include Domain Name In File !", { autoClose: 1000 });
      } else {
        setDomainLength(domainNames.length);
        return domainNames;
      }
    }
  };

  // dispatch redux action for verify ABM
  const dispatchReduxAction = (formValues, domainNames) => {
    setLoading(true);
    if (domainNames?.length > 1000) {
      alert("Domain Names Should Not Be More Than 1000");
    } else {
      let domainNamesWithoutInvalid = domainNames.filter(
        (row) => !unregisteredDomains.includes(row)
      );
      let payload = {
        product_sub_category_name: formValues.product_sub_category_name,
        // price_range: formValues.price_range,
        // geolocation: formValues.geolocation,
        domainName: domainNamesWithoutInvalid,
        user_id: userId,
      };
      if (isStaff) {
        payload = { ...payload, staff_id: userData.userInfo?.user_staff?.user };
      }
      // Dispatch your Redux action with payload
      dispatch(
        getAbmScore({ is_credit: true, ...payload }, token, (result) => {
          if (result?.status === 200) {
            navigate("/abm-lal/abm-results", { state: payload });
            localStorage.setItem('abmpayload', JSON.stringify(payload));
            localStorage.setItem("location", "/abm-lal/abm-results");
            setLoading(false);
          } else if (result.status === 400) {
            setLoading(false);
            setMessage(result.message);
            setIsDialogOpen(true);
          }
        })
      );
    }
  };
  const handleClose = () => setIsDialogOpen(false);

  // common download template function with domain names
  // const downloadExcelTemplate = () => {
  //   // Create a Blob with Excel content
  //   const excelContent = "Domain\niso.com"; // Column names
  //   const blob = new Blob([excelContent], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   // Save the Blob as a file using FileSaver.js
  //   saveAs(blob, "template.xlsx");

  //   // // Create an anchor element to trigger the download
  //   // const a = document.createElement("a");
  //   // a.href = window.URL.createObjectURL(blob);
  //   // a.download = "template.xlsx"; // File name
  //   // document.body.appendChild(a);
  //   // a.click();
  //   // document.body.removeChild(a);
  // };

  // Function to handle download button click
  const downloadTemplate = (templateFileName) => {
    // Construct the URL for the Excel template file
    const templateUrl = excelTemplate;

    // Use file-saver library to trigger the download
    saveAs(templateUrl, templateFileName);
  };

  //this handlesubmit function for generate LAL
  const handleLALSubmit = (values) => {
    if (values.uploadLALFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileType = values.uploadLALFile.name
          .split(".")
          .pop()
          .toLowerCase();
        if (fileType === "csv") {
          parseCSVForLAL(e.target.result, values);
        } else if (fileType === "xlsx") {
          parseXLSXForLAL(e.target.result, values);
        } else {
          toast.error("Unsupported file type");
        }
      };
      reader.readAsBinaryString(values.uploadLALFile);
    }
  };
  // parse function for LAL for both csv and XLSX
  const parseCSVForLAL = (csvContent, formValues) => {
    Papa.parse(csvContent, {
      complete: (result) => {
        const domainNames = result.data.map((row) => row.Domain);
        if (domainNames === undefined)
          toast("provided file is empty, please use LAL template !");
        else {
          if (domainNames.length === 0) {
            toast("Please Include Domain Name In File !", { autoClose: 1000 });
          } else {
            dispatchReduxActionForLAL(formValues, domainNames);
          }
        }
        // dispatchReduxActionForLAL(formValues, domainNames);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const parseXLSXForLAL = (xlsxContent, formValues) => {
    const workbook = XLSX.read(xlsxContent, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const domainIndex = parsedData[0]?.indexOf("Domain");
    if (domainIndex === undefined)
      toast("provided file is empty, please use LAL template !");
    else {
      const domainNames = parsedData
        .slice(1)
        .map((row) => row[domainIndex])
        .filter((domain) => domain); // Use index obtained from indexOf
      if (domainNames.length === 0) {
        toast("Please Include Domain Name In File !", { autoClose: 1000 });
      } else {
        dispatchReduxActionForLAL(formValues, domainNames);
      }
    }
    // const domainNames = parsedData.slice(1).map((row) => row[domainIndex]);

    // dispatchReduxActionForLAL(formValues, domainNames);
  };

  //dispatch redux action for LAL
  const dispatchReduxActionForLAL = (formValues, domainNames) => {
    setLoading(true);
    let domainNamesWithoutInvalid = domainNames.filter(
      (row) => !unregisteredDomains.includes(row)
    );

    let payload = {
      product_sub_category_name: formValues.product_sub_category_name,
      domainNames: domainNamesWithoutInvalid,
      user_id: userId,
    };
    if (isStaff) {
      payload = { ...payload, staff_id: userData.userInfo?.user_staff?.user };
    }
    // Dispatch your Redux action with payload
    dispatch(
      getLAlListResult(payload, token, (result) => {
        if (result?.status === 200) {
          setLoading(false);
          navigate("/abm-lal/lal-results", { state: payload });
          localStorage.setItem("location", "/abm-lal/lal-results");
        } else if (result?.status === 400) {
          setIsDialogOpen(true);
          setMessage(result.message)
          setLoading(false);
        }
      })
    );
  };

  const onClose = () => {
    setModal(false);
    if (navigateTo === "abm-lal") {
      navigate("/abm-lal");
    } else if (navigateTo === "phone-verification") {
      navigate("/phone-verification");
    }
  };

  return (
    <>
      <div>
        <ToastModal
          type={toastData.type}
          heading={toastData.heading}
          message={toastData.message}
          isVisible={isVisible}
          onClose={() => {
            setIsVisible(false)
            setSearchlimitError(true)
          }}
        />
      </div>
      {modal && (
        <DomainModel
          showModal={modal}
          onClose={onClose}
          message={`${domainlength -
            unregisteredDomains?.length +
            "/" +
            domainlength +
            "   Domains are Found."
            }`}
          btnName="Download Invalid Domains"
          data={unregisteredDomains}
        />
      )}
      <div className="pageHeadingBlock commonHeading">
        <div className="pageTitle">
          <h3>ABM / LAL</h3>
        </div>
        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}{" "}
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper abmLAL">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={ABM_Validation}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                    }) => (
                      <Form className="abmForm">
                        <div className="icpForm">
                          <div className="abmFormHeadig text-center">
                            <h5>Verify your Account Based Marketing (ABM)</h5>
                          </div>
                          <div className="form-group">
                            <label>
                              My Product Subcategory{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Select
                              options={getAllSubProductCategories}
                              styles={customStyles}
                              // className="selectBox"
                              className={`selectBox ${touched.product_sub_category_name &&
                                errors.product_sub_category_name
                                ? "error-input"
                                : ""
                                }`}
                              placeholder="Select"
                              name=" product_sub_category_name"
                              value={getAllSubProductCategories.find(
                                (option) =>
                                  option.value ===
                                  values.product_sub_category_name
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "product_sub_category_name",
                                  selectedOption.value
                                )
                              }
                              onBlur={handleBlur}
                            />
                            {touched.product_sub_category_name &&
                              errors.product_sub_category_name && (
                                <div className="error-message">
                                  {errors.product_sub_category_name}
                                </div>
                              )}
                          </div>
                          {/* <div className="form-group">
                            <label>
                              Select Selling Price of My Product{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Select
                              options={getSellingPrice}
                              styles={customStyles}
                              className="selectBox"
                              placeholder="Select"
                              name="price_range"
                              value={getSellingPrice.find(
                                (option) => option.value === values.price_range
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "price_range",
                                  selectedOption.value
                                )
                              }
                              onBlur={handleBlur}
                            />
                            {errors.price_range && (
                              <div className="error-message">
                                {errors.price_range}
                              </div>
                            )}
                          </div> */}
                          {/* <div className="form-group">
                            <label>Geolocation I want to target </label>
                            <Select
                              options={getGeoLocation}
                              styles={customStyles}
                              className="selectBox"
                              placeholder="Select"
                              name="geolocation"
                              value={getGeoLocation.find(
                                (option) => option.value === values.geolocation
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "geolocation",
                                  selectedOption.value
                                )
                              }
                              onBlur={handleBlur}
                            />
                          </div> */}
                          <div className="form-group m-0">
                            <label>
                              Upload ABM file{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <div className="uploadedFile">
                              <div className="fileDetilsItem">
                                <p>
                                  <img src={excelIcon} alt="" />
                                  ABM Template
                                </p>
                                <div className="downloadFile">
                                  <button
                                    type="button"
                                    className="btn btnPrimaryOutline"
                                    onClick={() =>
                                      downloadTemplate("ABM template.csv")
                                    }
                                  >
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div /* className="uploadDocuments" */
                              className={`uploadDocuments ${touched.uploadAbmFile && errors.uploadAbmFile
                                ? "box"
                                : ""
                                }`}
                            >
                              <input
                                className="uploadDocInput"
                                id="userImgUploadInput"
                                type="file"
                                accept=".xlsx, .csv"
                                onChange={(e) => {
                                  if (isSubscribed.data.is_free_trial && isSubscribed.data.per_day_search_limit_left === 0) {
                                    show_daily_limit_exide()
                                    setClickFrom('ABM')
                                    return
                                  }
                                  setFieldValue(
                                    "uploadAbmFile",
                                    e.currentTarget.files[0]
                                  );
                                  const excelFilee = e.currentTarget.files[0];
                                  if (excelFilee) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      // Parse the uploaded file content based on file type
                                      const fileType = excelFilee?.name
                                        .split(".")
                                        .pop()
                                        .toLowerCase();
                                      if (fileType === "csv") {
                                        const getDomainByCSV =
                                          getDomainNameFromSelectedFile(
                                            e.target.result
                                          );

                                        if (getDomainByCSV) {
                                          dispatch(
                                            checkDomainNames(
                                              getDomainByCSV,
                                              (result) => {
                                                if (result.valid_domain > isSubscribed?.data?.abm_limit && isSubscribed?.data?.is_free_trial) {
                                                  setLimitError(true)
                                                  return false
                                                }
                                                if (result?.status === 200) {
                                                  setLimitError(false)
                                                  setUnregisteredDomains(
                                                    result?.data
                                                  );
                                                  if (
                                                    result?.data?.length > 0
                                                  ) {
                                                    setModal(true);
                                                  }

                                                  setNavigateTo("abm-lal");
                                                } else if (
                                                  result.status === 400
                                                ) {
                                                  setModal(true);
                                                }
                                              }
                                            )
                                          );
                                        }
                                      } else if (fileType === "xlsx") {
                                        const getDomainByXLSX =
                                          getDomainNameFromSelectedFile(
                                            e.target.result
                                          );

                                        if (getDomainByXLSX) {
                                          dispatch(
                                            checkDomainNames(
                                              getDomainByXLSX,
                                              (result) => {
                                                if (result.valid_domain > isSubscribed?.data?.abm_limit && isSubscribed?.data?.is_free_trial) {
                                                  setLimitError(true)
                                                  return false
                                                }

                                                if (result?.status === 200) {
                                                  setLimitError(false)

                                                  setUnregisteredDomains(
                                                    result?.data
                                                  );
                                                  if (
                                                    result?.data?.length > 0
                                                  ) {
                                                    setModal(true);
                                                  }

                                                  setNavigateTo("abm-lal");
                                                } else if (
                                                  result.status === 400
                                                ) {
                                                  setModal(true);
                                                }
                                              }
                                            )
                                          );
                                        }
                                      } else {
                                        toast.error("Unsupported file type");
                                      }
                                    };
                                    reader.readAsBinaryString(excelFilee);
                                  }
                                }}
                                onBlur={handleBlur}
                              />

                              <div className="uploadDocContent">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="49"
                                  height="34"
                                  viewBox="0 0 49 34"
                                  fill="none"
                                >
                                  <path
                                    d="M39.5063 12.9996C38.8205 9.52414 36.9493 6.39456 34.2123 4.14543C31.4754 1.8963 28.0425 0.667168 24.5 0.667969C18.5996 0.667969 13.475 4.0163 10.9229 8.9163C7.92214 9.24058 5.14706 10.6624 3.13087 12.9084C1.11469 15.1545 -0.000350558 18.0664 8.26727e-08 21.0846C8.26727e-08 27.8426 5.49208 33.3346 12.25 33.3346H38.7917C44.4267 33.3346 49 28.7613 49 23.1263C49 17.7363 44.8146 13.3671 39.5063 12.9996ZM28.5833 19.043V27.2096H20.4167V19.043H14.2917L23.7854 9.54922C24.1938 9.14089 24.8267 9.14089 25.235 9.54922L34.7083 19.043H28.5833Z"
                                    fill="#414141"
                                    fillOpacity="0.2"
                                  />
                                </svg>

                                {values?.uploadAbmFile?.name ? (
                                  <p>{values?.uploadAbmFile?.name}</p>
                                ) : (
                                  <p className="m-0">
                                    Select/Drop File to upload
                                    <span className="m-0">
                                      Must be .xlsx / .csv file Use the sample
                                      template.
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                            {touched.uploadAbmFile && errors.uploadAbmFile && (
                              <div className="error-message">
                                {errors.uploadAbmFile}
                                {isSubscribed.data.is_free_trial && isSubscribed.data.per_day_search_limit_left === 0 && (<div className="error-message">Cannot upload file, Credit exhausted!</div>)}
                              </div>
                            )}
                            {(!touched.uploadAbmFile || !errors.uploadAbmFile) &&
                              serchlimitError &&
                              isSubscribed.data.is_free_trial && clickFrom === 'ABM' &&
                              isSubscribed.data.per_day_search_limit_left === 0 && (
                                <div className="error-message">Cannot upload file, Credit exhausted!</div>
                              )}
                            {
                              limitError && (<div className="error-message">You have only {isSubscribed.data.abm_limit} credits remaining for the ABM search.</div>)
                            }
                          </div>

                          {loading && <>
                            <center><p

                              style={{
                                zIndex: "9999",
                                margin: "auto !important",
                                textAlign: "center",
                                color: "white",
                                position: "fixed",
                                top: "75%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "40%",
                                fontWeight: "200",
                                fontSize: "18px"
                              }}
                            // className="highlight-text"
                            >

                              {randomMessage}
                            </p>
                            </center></>}

                          <div className="form-group formSubmit m-0">
                            <button
                              disabled={limitError}
                              type="submit"
                              className="btn btnPrimaryOutline"
                            >
                              Verify My ABM
                            </button>
                            <p className="icpNote">
                              <strong>Note: </strong> Each 'Verify My ABM'
                              action deducts one search from your available
                              credits.
                            </p>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="col-12 col-md-6">
                  <Formik
                    initialValues={initialLALValues}
                    onSubmit={handleLALSubmit}
                    validationSchema={LAL_Validation}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      touched,
                      errors,
                    }) => (
                      <Form className="abmForm lalForm">
                        <div className="icpForm">
                          <div className="abmFormHeadig text-center">
                            <h5>Generate Look-Alike (LAL)</h5>
                          </div>

                          <div className="form-group">
                            <label>
                              My Product Subcategory{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Select
                              options={getAllSubProductCategories}
                              styles={customStyles}
                              // className="selectBox"
                              className={`selectBox ${touched.product_sub_category_name &&
                                errors.product_sub_category_name
                                ? "error-input"
                                : ""
                                }`}
                              placeholder="Select"
                              name=" product_sub_category_name"
                              value={getAllSubProductCategories.find(
                                (option) =>
                                  option.value ===
                                  values.product_sub_category_name
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "product_sub_category_name",
                                  selectedOption.value
                                )
                              }
                              onBlur={handleBlur}
                            />
                            {touched.product_sub_category_name &&
                              errors.product_sub_category_name && (
                                <div className="error-message">
                                  {errors.product_sub_category_name}

                                </div>
                              )}

                          </div>
                          <div className="form-group m-0">
                            <label>
                              Upload Top Performing Accounts{" "}
                              <span className="required">&#42;</span>
                            </label>{" "}
                            <div className="uploadedFile">
                              <div className="fileDetilsItem">
                                <p>
                                  <img src={excelIcon} alt="" />
                                  LAL Template
                                </p>
                                <div className="downloadFile">
                                  <button
                                    type="button"
                                    className="btn btnPrimaryOutline"
                                    onClick={() =>
                                      downloadTemplate("LAL template.csv")
                                    }
                                  >
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div /* className="uploadDocuments" */
                              className={`uploadDocuments ${touched.uploadLALFile && errors.uploadLALFile
                                ? "box"
                                : ""
                                }`}
                            >
                              <input
                                className="uploadDocInput"
                                id="userImgUploadInput"
                                type="file"
                                accept=".xlsx, .csv"
                                // onChange={(e) =>
                                //   setFieldValue(
                                //     "uploadLALFile",
                                //     e.currentTarget.files[0]
                                //   )
                                // }

                                onChange={(e) => {
                                  if (isSubscribed.data.is_free_trial && isSubscribed.data.per_day_search_limit_left === 0) {
                                    show_daily_limit_exide()
                                    setClickFrom('LAL')
                                    return
                                  }
                                  setFieldValue(
                                    "uploadLALFile",
                                    e.currentTarget.files[0]
                                  );
                                  const excelFilee = e.currentTarget.files[0];
                                  if (excelFilee) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      // Parse the uploaded file content based on file type
                                      const fileType = excelFilee?.name
                                        .split(".")
                                        .pop()
                                        .toLowerCase();
                                      if (fileType === "csv") {
                                        const getDomainByCSV =
                                          getDomainNameFromSelectedFile(
                                            e.target.result
                                          );

                                        if (getDomainByCSV) {
                                          dispatch(
                                            checkDomainNames(
                                              getDomainByCSV,
                                              (result) => {
                                                console.log(
                                                  result,
                                                  "result?.data"
                                                );

                                                if (result?.status === 200) {
                                                  setUnregisteredDomains(
                                                    result?.data
                                                  );
                                                  if (
                                                    result?.data?.length > 0
                                                  ) {
                                                    setModal(true);
                                                  }

                                                  setNavigateTo("abm-lal");
                                                } else if (
                                                  result.status === 400
                                                ) {
                                                  setModal(true);
                                                }
                                              }
                                            )
                                          );
                                        }
                                      } else if (fileType === "xlsx") {
                                        const getDomainByXLSX =
                                          getDomainNameFromSelectedFile(
                                            e.target.result
                                          );

                                        if (getDomainByXLSX) {
                                          dispatch(
                                            checkDomainNames(
                                              getDomainByXLSX,
                                              (result) => {
                                                if (result?.status === 200) {
                                                  setUnregisteredDomains(
                                                    result?.data
                                                  );
                                                  if (
                                                    result?.data?.length > 0
                                                  ) {

                                                    setModal(true);
                                                  }
                                                  setNavigateTo("abm-lal");
                                                } else if (
                                                  result.status === 400
                                                ) {
                                                  setModal(true);
                                                }
                                              }
                                            )
                                          );
                                        }
                                      } else {
                                        toast.error("Unsupported file type");
                                      }
                                    };
                                    reader.readAsBinaryString(excelFilee);
                                  }
                                }}
                              />

                              <div className="uploadDocContent">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="49"
                                  height="34"
                                  viewBox="0 0 49 34"
                                  fill="none"
                                >
                                  <path
                                    d="M39.5063 12.9996C38.8205 9.52414 36.9493 6.39456 34.2123 4.14543C31.4754 1.8963 28.0425 0.667168 24.5 0.667969C18.5996 0.667969 13.475 4.0163 10.9229 8.9163C7.92214 9.24058 5.14706 10.6624 3.13087 12.9084C1.11469 15.1545 -0.000350558 18.0664 8.26727e-08 21.0846C8.26727e-08 27.8426 5.49208 33.3346 12.25 33.3346H38.7917C44.4267 33.3346 49 28.7613 49 23.1263C49 17.7363 44.8146 13.3671 39.5063 12.9996ZM28.5833 19.043V27.2096H20.4167V19.043H14.2917L23.7854 9.54922C24.1938 9.14089 24.8267 9.14089 25.235 9.54922L34.7083 19.043H28.5833Z"
                                    fill="#414141"
                                    fillOpacity="0.2"
                                  />
                                </svg>

                                {values?.uploadLALFile?.name ? (
                                  <p>{values?.uploadLALFile?.name}</p>
                                ) : (
                                  <p className="m-0">
                                    Select/Drop File to upload
                                    <span className="m-0">
                                      Must be .xlsx / .csv file / Use the sample
                                      template.
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                            {touched.uploadLALFile && errors.uploadLALFile && (
                              <div className="error-message">
                                {errors.uploadLALFile}
                                {isSubscribed.data.is_free_trial && isSubscribed.data.per_day_search_limit_left === 0 && (<div className="error-message">Cannot upload file, Credit exhausted!</div>)}
                              </div>
                            )}
                            {(!touched.uploadLALFile || !errors.uploadLALFile) &&
                              serchlimitError &&
                              isSubscribed.data.is_free_trial && clickFrom === 'LAL' &&
                              isSubscribed.data.per_day_search_limit_left === 0 && (
                                <div className="error-message">Cannot upload file, Credit exhausted!</div>
                              )}
                          </div>
                          <div className="form-group formSubmit m-0">
                            <button
                              type="submit"
                              className="btn btnPrimaryOutline"
                            >
                              Generate LAL
                            </button>
                            <p className="icpNote">
                              <strong>Note: </strong> Each 'Generate LAL' action
                              deducts one search from your available credits.
                            </p>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {isDialogOpen && (
                <SearchModel
                  isDialogOpen={isDialogOpen}
                  message={message}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ABMLAL;
